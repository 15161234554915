import Vue from 'vue'
import App from './App.vue'
import Encuesta from "@/views/Encuesta.vue";
import SurveyPreview from "@/views/SurveyPreview.vue";
import VueRouter from 'vue-router'
import store from './store'

const routes = [  
  // {
  //   path: '*',
  //   name: 'All',
  //   component: Encuesta
  // },
  {
    path: '/survey/campaign/:entorno',
    name: 'Encuesta',
    component: Encuesta,
  },
  {
    path: '/survey/preview/:entorno/:id/:token',
    name: 'SurveyPreview',
    component: SurveyPreview,
  },
];

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
