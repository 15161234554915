<template>
  <div>
    <h3>Previsualización de argumentario</h3>
    <p>
      Nombre de campaña: {{name}} <br>
      Tiempo estimado del argumentario: {{time}}s <br>
      Descripción de campaña: {{description}} <br>
    </p>
    <survey-view :jsbody="jsBody" />
  </div>
</template>

<script>
    import axios from "axios";
    import SurveyView from "@/components/SurveyView";

export default {
  name: "SurveyPreview",
  components: { 
    SurveyView
  },
  data() {
      return {
        jsBody: null,
        name: null,
        description: null,
        time: null
      };
  },
  methods: {
    getSurveyPreview(){
      var id_encuesta = this.$route.params.id;
      var token_email = this.$route.params.token;
      const data = { id_encuesta, token_email };
      axios.post(this.url_api + "survey/preview", data).then(response => {
        this.jsBody = JSON.parse(response.data.body);
        this.name = response.data.nombre;
        this.description = response.data.descripcion;
        this.time = response.data.tiempo;
      }).catch(() => {
      })
    },
  },
  created() {
    // Establece entorno, url y token
    this.$store.dispatch('setEntorno', this.$route.params.entorno);
    this.url_api = this.$store.state.url_api;
    this.entorno = this.$store.state.entorno;

    this.getSurveyPreview();
  },
  
};
</script>

<style scoped>
</style>
