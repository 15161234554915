<template>
    <div>
      <survey :survey="survey" />
    </div>
</template>

<script>
import * as Survey from "survey-vue";
import "survey-vue/survey.css";
import citasWidget from "@/assets/js/survey_widgets/widget_citas.js";


export default {
  name: "SurveyView",
  props:{
    jsbody: Object
  },
  data() {
    const survey = new Survey.Model(this.jsbody);
    return {
      survey: survey,
    };
  },
  watch: { 
    // Como se crea el componente y después se solicita el body, entonces no carga la encuesta.
    // Lo que hacemos es un watcher de la propiedad, para que cuando cambie (porque ya dispone del json de la encuesta), se cree un nuevo objeto survey
    jsbody: function(newVal) {
      this.survey = new Survey.Model(newVal);
      this.survey.onComplete.add(sender => {
        // Lanza los datos como evento para obtenerlo desde el componente padre
        this.$emit('onComplete', sender.data);
      });
      
    },
  },
  created() {
    citasWidget.instance(Survey, this.$store.state);
    this.survey = new Survey.Model(this.jsbody);
    // Edita el tema usando como color principal el color primario de la web
    var mainColor = "#1A1B36";
    var defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
    defaultThemeColorsSurvey["$main-color"] = mainColor;
    Survey.StylesManager.applyTheme();
    this.survey.onComplete.add(sender => {
        // Lanza los datos como evento para obtenerlo desde el componente padre
        this.$emit('onComplete', sender.data);
    });

  },
   

};
</script>

<style scoped>
</style>
