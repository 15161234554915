import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token_encuestas : "",
    entorno : "",
    url_api : "",
  },
  mutations: {
    updateEntorno (state, entorno) {
      switch (entorno) {
        case 'development':
          state.token_encuestas = "aFDrs-EDf4rge.dFR3DE4.E4rthtG.434TF-F4F3";
          state.entorno = entorno;
          state.url_api = 'http://localhost/api/';
          break;
        case 'staging':
          state.token_encuestas = "aFDrs-EDf4rge.dFR3DE4.E4rthtG.434TF-F4F3";
          state.entorno = entorno;
          state.url_api = 'https://stgapi.actionscall.com/api/';
          break;
        case 'production':
          state.token_encuestas = "aFDrs-EDf4rge.dFR3DE4.E4rthtG.434TF-F4F3";
          state.entorno = entorno;
          state.url_api = 'https://api.actionscall.com/api/';
          break
        default:
          break;
      }
    }
  },
  actions: {
    setEntorno: function (context, entorno) {
      context.commit('updateEntorno', entorno)
    }
  }
})
