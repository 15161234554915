import Vue from 'vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es/es';
import axios from "axios";


var vmDate;
var vmTime;
var url_api;
// eslint-disable-next-line no-unused-vars
var entorno;
var token_encuestas;
var reservas;

export default {
    date: "",
    time: "",
    //el nombre del widget. Debe ser único y escrito en minúsculas.
    name: "citas",
    //Este título se mostrará en la caja de herramientas de SurveyJS Creator
    title: "Citas",
    //Ícono que se muestra en la caja de herramientas de SurveyJS Creator
    //Puede dejar esta propiedad vacía para mostrar el icono predeterminado
    iconName: "icon-datepicker",
    //It is a required function for a custom type widget, that user can see on toolbox
    //Commonly you check here, that all scripts/css files are loaded on the page
    widgetIsLoaded: function () {
        return true; //We do not have external scripts
        //return typeof $ == "function"; //example of checking on loading jQuery
    },
    //Applies for a question that has our own type
    isFit: function (question) {
        return question.getType() == "citas";
    },
    init() {
        // Registra un nuevo tipo de pregunta usando un objeto empty question  como base
        // Lo movemos a la función instance porque es necesario añadirlo al objeto Survey y así queda simplificado su uso
        // Survey.Serializer.addClass("citas", [], null, "empty");
    },
    //We do not need default rendering here.
    //SurveyJS will render this template by default
    htmlTemplate: `
    <div
        <div class="cita-block">
            <div id="fecha"></div>
            <div id="hora"></div>
        </div>
    </div>`,
    //Our element will be rendered base on template.
    //We do not need to do anything here
    afterRender: function (question, el) {
        // Recoge todas las reservas;
        getReservas(question.getPropertyValue("citaId"));
        // Crea los componentes de fecha y hora en función del tipo de cita
        this.createDate(question, el);
        if(question.getPropertyValue("tipoCita") == "fechaHora"){
            this.createTime(question, el);
        }

        // Monta un objeto JSON en la respuesta de la pregunta "Citas"
        // question.value = {
        //     "id": question.getPropertyValue("citaId"),
        //     "date": this.date, 
        //     "time": this.time
        // };


        // // Busca el bloque que contiene los elementos
        // var citaBlock = el.getElementsByClassName("cita-block");
        // if (citaBlock.length == 0){
        //     return; // Si no lo encuentra
        // }
        // citaBlock = citaBlock[0];

        // // Establece el valor por defecto vacío
        // var date = "";
        // var time = "";
        // // Monta un objeto JSON en la respuesta de la pregunta "Citas"
        // question.value = {
        //     "id": question.getPropertyValue("citaId"),
        //     "date":date, 
        //     "time":time
        // };

        // // Comprueba si es solo fecha
        // if(question.getPropertyValue("tipoCita") == "fecha"){
        //     el.querySelector("#hora").classList.add("invisible");
        //     question.value.date = null;
        //     citaBlock.querySelector("[name='date']").value = "";
        // }else{
        //     el.querySelector("#hora").classList.remove("invisible");
        // }
        
        // // Actualiza el valor de las respuestas cuando se cambia los valores de los input
        // var changingValue = false;
        // var updateQuestionValue = function () {
        //     if (changingValue) {
        //         return;
        //     }
        //     changingValue = true;
        //     date = citaBlock.querySelector("[name='date']").value;
        //     time = citaBlock.querySelector("[name='time']").value;
        //     question.value = {
        //         "id": question.getPropertyValue("citaId"),
        //         "date":date, 
        //         "time":time
        //     };
        //     changingValue = false;
        // };

        // // Añade listeners con la función anterión que actualiza la respuestas en función del valor de los input
        // if (citaBlock.addEventListener) {
        //     var types = [
        //     "input",
        //     "DOMNodeInserted",
        //     "DOMNodeRemoved",
        //     "DOMCharacterDataModified",
        //     ];
        //     for (var i = 0; i < types.length; i++) {
        //         citaBlock.addEventListener(types[i], updateQuestionValue, false);
        //     }
        // }
        // //Si el valor de la pregunta cambia en tiempo de ejecución, actualice el valor
        // question.valueChangedCallback = function () {
        //     if (changingValue) {
        //         return;
        //     }
        //     changingValue = true;
        //     if(!question.value){
        //         question.value = {
        //             "id": question.getPropertyValue("citaId"),
        //             "date":date, 
        //             "time":time
        //         };
        //     }
        //     citaBlock.querySelector("[name='date']").value = question.value.date || "";
        //     citaBlock.querySelector("[name='time']").value = question.value.time || "";
        //     changingValue = false;

        //     // Comprueba si es solo fecha
        //     if(question.getPropertyValue("tipoCita") == "fecha"){
        //         el.querySelector("#hora").classList.add("invisible");
        //         question.value.date = null;
        //         citaBlock.querySelector("[name='date']").value = "";
        //     }else{
        //         el.querySelector("#hora").classList.remove("invisible");
        //     }
        // };


        // Deshabilita copia, cambio de tipo y añadir al toolbox
        // question.allowingOptions.allowCopy = false;
        // question.allowingOptions.allowChangeType = false;
        // question.allowingOptions.allowAddToToolbox = false;
        
    },

    setDate(date){
        this.fecha = date;
        vmDate.value = date;
    },
    getDate(){
        return this.fecha;
    },

    createDate(question, el){
        // Crea un componente heredado de DatePicker estableciendo propiedades de entrada
        var Picker = Vue.extend(DatePicker);
        vmDate = new Picker({
            propsData: {
                format: "DD/MM/YYYY",
                valueType: "DD/MM/YYYY",
                clearable: false,
                // Cada día que se muestra pasa por la función como date y si devuelve true deshabilita
                disabledDate: function (date) {
                    var diasSemana = []
                    diasSemana["lunes"] = 1;
                    diasSemana["martes"] = 2;
                    diasSemana["miercoles"] = 3;
                    diasSemana["jueves"] = 4;
                    diasSemana["viernes"] = 5;
                    diasSemana["sabado"] = 6;
                    diasSemana["domingo"] = 0;

                    var fechaDesde = strToDate(question.getPropertyValue("fechaDesde"));
                    var fechaHasta = strToDate(question.getPropertyValue("fechaHasta"));
                    // Día de la semana activo getDay empieza en 0 domingo
                    var diaSemanaValido = false;
                    question.getPropertyValue("dias").forEach(dia => {
                        if(diasSemana[dia] == date.getDay()){
                            diaSemanaValido = true;
                        }
                    })
                    var invalido = (fechaDesde > date) || (fechaHasta < date || !diaSemanaValido);
                    if(!invalido){
                        // invalido = await checkDate(date, question.getPropertyValue("citaId"));
                        var fecha = date.toLocaleDateString("es-ES");
                        try {
                            if(reservas["citas"][fecha]){
                                if(reservas["citas"][fecha]["reservas"] >= reservas["num_plazas_dia"]){
                                    invalido = true;
                                }
                            }
                        } catch (error) {
                            invalido = false;
                        }
                    }
                    return invalido;
                }, 
            }
        });
        // Crea un objeto date con la fecha y hora actual
        // let today = new Date();
        // Configuración para la fecha
        // Asigna el valor de today como valor inicial del componente vmDate(DatePicker). Como el value-type es con un formato, espera eso mismo, si no, usaría un objeto Date de javascript
        // vmDate.$props.value = today.toLocaleDateString("es-ES");

        // Establece en el atributo date la fecha inicial formateada a partir del nuevo objeto today (Date)
        // this.date = today.toLocaleDateString("es-ES");

        // Monta el componente vmDate (DatePicker) sobre el div con id fecha, se usa "el" en vez de document, para que busque únicamente en la instancia de este widget
        vmDate.$mount(el.querySelector("#fecha"));

        // Listener que al producirse un cambio en el input del componente vmDate, establece el nuevo valor al atribute value del objeto vmDate (DatePicker)
        vmDate.$on("input", function(newValue) {
            this.value = newValue;
            vmTime.clear();
        });

        // Listener que cuando el atributo value del componente vmDate (DatePicker) cambia, establece el nuevo valor al atributo date del widget
        vmDate.$watch('value', newValue => {
            // Si es tipo fecha y hora al seleccionar fecha habilita el input de horas 
            if(question.getPropertyValue("tipoCita") == "fechaHora"){
                vmTime.$props.disabled = false;
                vmTime.$props.disabledTime = function (date) {
                    var hora = date.toLocaleTimeString("es-ES");
                    var disable = false;
                    try {
                        if(reservas["citas"][newValue]["horas"]){
                            reservas["citas"][newValue]["horas"].forEach(function (citas) {
                                var timeArray = hora.split(":");
                                hora = timeArray[0].padStart(2, "0")+":";
                                hora += timeArray[1].padStart(2, "0")+":";
                                hora += timeArray[2].padStart(2, "0");
                                if(hora == citas["hora"] && citas["reservas"] >= reservas["num_recursos"]){
                                    disable = true;
                                    return disable;
                                }
                            });
                        }
                    } catch (error) {
                        disable = false;
                    }
                    return disable;
                };
            }
            this.date = newValue;
            this.time = "";
            question.value = {
                "id": question.getPropertyValue("citaId"),
                "date": this.date, 
                "time": this.time
            };
        });
    },
    

    createTime(question, el){    
        var horaInicio = question.getPropertyValue("horarioInicio");
        var horarioFin = question.getPropertyValue("horarioFin");
        var tramoMinutos = question.getPropertyValue("tramoMinutos");

        // Se adapta la hora final para que las citas sean completas en función del tramo de minutos Ej: la ultima cita es a las 19:40 y finaliza a las 20h con tramos de 40min, entonces ponemos como fin las 19:40
        var iniArr = horaInicio.split(":");
        var finArr = horarioFin.split(":");
        var minutosHorario = (finArr[0]-iniArr[0])*60 + (finArr[1]-iniArr[1]);
        minutosHorario = minutosHorario - (minutosHorario%tramoMinutos);
        minutosHorario = parseInt(iniArr[0])*60+ parseInt(iniArr[1]) + minutosHorario - tramoMinutos; // Restamos tramos minutos al total para que no muestre hora fin si no última cita disponible
        horarioFin = Math.trunc(minutosHorario/60)+":"+minutosHorario%60
        console.log(horarioFin);
        

        var tramoFormat = "00:00";
        if(tramoMinutos > 0){
            var horas = Math.trunc(tramoMinutos/60);
            var minutos = tramoMinutos%60;
            horas = (horas < 10)? "0"+horas: horas.toString();
            minutos = (minutos < 10)? "0"+minutos: minutos.toString();
            tramoFormat = horas+":"+minutos;
        }
    
        // Crea un componente heredado de DatePicker estableciendo propiedades de entrada
        var Picker = Vue.extend(DatePicker);  
        vmTime = new Picker({
            propsData: {
                format: "HH:mm",
                valueType: "HH:mm",
                type: "time",
                timePickerOptions: {start: horaInicio, step:tramoFormat , end: horarioFin, format: 'HH:mm' },
                clearable: false,
                disabled: true,
            }
        });
        
        // Crea un objeto date con la fecha y hora actual
        // let today = new Date();
        // Configuración para la hora
        // Asigna el valor de today como valor inicial del componente vmTime(DatePicker). Como el value-type es con un formato, espera eso mismo, si no, usaría un objeto Date de javascript
        // vmTime.$props.value = today.toLocaleTimeString("es-ES");

        // Establece en el atributo date la hora inicial formateada a partir del nuevo objeto today (Date)
        // this.time = today.toLocaleTimeString("es-ES");

        // Monta el componente vmTime (DatePicker) sobre el div con id hora, se usa "el" en vez de document, para que busque únicamente en la instancia de este widget
        vmTime.$mount(el.querySelector("#hora"));

        // Listener que al producirse un cambio en el input del componente vmTime, establece el nuevo valor al atribute value del objeto vmTime (DatePicker)
        vmTime.$on("input", function(newValue) {
            this.value = newValue;
        });

        // Listener que cuando el atributo value del componente vmTime (DatePicker) cambia, establece el nuevo valor al atributo date del widget
        vmTime.$watch('value', newValue => {
            this.time = newValue;
            question.value = {
                "id": question.getPropertyValue("citaId"),
                "date": this.date, 
                "time": this.time
            };
        });
    },

check(surveyCreator, extras, errores){
        var extrasLocal = extras;
        var hasError = false;
        surveyCreator.JSON.pages.forEach(page => {
            if(page.elements){
            page.elements.forEach(element => {
                if(element.type == "citas"){
                    extrasLocal.push(element);
                    // Parámetros comunes necesarios para todos
                    if( !element.type ){
                        hasError = true;
                        errores.push("Falta propiedad 'Tipo de pregunta' en el componente citas\n");
                    }
                    if( !element.name ){
                        hasError = true;
                        errores.push("Falta propiedad 'Nombre' en el componente citas\n");
                    }
                    if( !element.citaId ){
                        hasError = true;
                        errores.push("Falta propiedad 'citaID' en el componente citas\n");
                    }
                    if( !element.tipoCita ){
                        hasError = true;
                        errores.push("Falta propiedad 'Tipo Cita' en el componente citas\n");
                    }
                    if( !element.fechaDesde ){
                        hasError = true;
                        errores.push("Falta propiedad 'Fecha inicio citas' en el componente citas\n");
                    }
                    if( !element.fechaHasta ){
                        hasError = true;
                        errores.push("Falta propiedad 'Fecha fin citas' en el componente citas\n");
                    }
                    if( !element.tramoCantidad ){
                        hasError = true;
                        errores.push("Falta propiedad 'Número de citas por tramo' en el componente citas\n");
                    }
                    if( !element.dias ){
                        hasError = true;
                        errores.push("Falta propiedad 'Días disponibles' en el componente citas\n");
                    }

                    // Parámetros necesarios para las citas de fecha y hora
                    if(element.tipoCita == "fechaHora" && !element.tramoMinutos ){
                        hasError = true;
                        errores.push("Falta propiedad necesaria 'Duración en minutos del tramo' en el componente citas\n");
                    }
                    if(element.tipoCita == "fechaHora" && !element.horarioInicio ){
                        hasError = true;
                        errores.push("Falta propiedad necesaria 'Horario inicio' en el componente citas\n");
                    }
                    if(element.tipoCita == "fechaHora" && !element.horarioFin ){
                        hasError = true;
                        errores.push("Falta propiedad necesaria 'Horario fin' en el componente citas\n");
                    }
                    if(hasError){
                        return false;
                    }
                }
            });
            }
        });
        if(!hasError){
            extras = extrasLocal;
        }
        return !hasError;
    },

    // Función para abstraer la instancia del widget
    instance(Survey, state){
        // Inicializa las variables para las llamadas del ws
        url_api = state.url_api;
        entorno = state.entorno;
        token_encuestas = state.token_encuestas;
        
        // Registra un nuevo tipo de pregunta usando un objeto empty question como base
        Survey.Serializer.addClass(this.name, [], null, "empty");

        //Register our widget in singleton custom widget collection and tells that, it is a new "customtype". 
        //We require this value for the second parameter if we want to show our custom widget as q new question in SurveyJS Creator toolbox
        Survey.CustomWidgetCollection.Instance.add(this, "customtype");

        // Añadimos las propiedades personalizadas
        this.addProperties(Survey);

        
        // OCULTAMOS ATRIBUTOS INECESARIOS PARA ESTE COMPONENTE
        
        // Sobrescribe el atributo readOnly para que no sea visible y no se pueda marcar
        Survey.Serializer.addProperty(this.name, {
            name: "readOnly",
            visible: false
        });
        // Sobrescribe el atributo visible para que no sea visible y no se pueda marcar
        Survey.Serializer.addProperty(this.name, {
            name: "visible",
            visible: false
        });
        // Sobrescribe el atributo para que no se vea
        Survey.Serializer.addProperty(this.name, {
            name: "correctAnswer",
            visible: false
        });
        // Sobrescribe el atributo para que no se vea
        Survey.Serializer.addProperty(this.name, {
            name: "requiredErrorText",
            visible: false
        });
        // Sobrescribe el atributo para que no se vea
        Survey.Serializer.addProperty(this.name, {
            name: "validators",
            visible: false
        });
        // Sobrescribe el atributo para que no se vea
        Survey.Serializer.addProperty(this.name, {
            name: "defaultValue",
            visible: false
        });
        // Sobrescribe el atributo para que no se vea
        Survey.Serializer.addProperty(this.name, {
            name: "valueName",
            visible: false
        });
        // Sobrescribe el atributo para que no se vea
        Survey.Serializer.addProperty(this.name, {
            name: "useDisplayValuesInTitle",
            visible: false
        });
        // Sobrescribe el atributo para que no se vea
        Survey.Serializer.addProperty(this.name, {
            name: "valueName",
            visible: false
        });
    },


    // Establecemos propiedades personalizadas
    addProperties(Survey){
        Survey.Serializer.addProperty(this.name, {
            name: "citaId",
            displayName: "Cita ID",
            // default: false,
            category: "general",
            visibleIndex: 0,
            isRequired: true,
            visible: false,
            readOnly: true,
        });
        Survey.Serializer.addProperty(this.name, {
            name: "tipoCita",
            displayName: "Tipo Cita",
            type: "dropdown",
            // default: "fechaHora", // Los valores por defecto no se muestran en el json
            choices: [
                { value: "fechaHora", text: "Fecha y hora" },
                { value: "fecha", text: "Solo fecha" },
            ],
            value: "fechaHora",
            category: "general",
            visibleIndex: 1,
            isSerializable: true,
            isRequired: true,
        });
        Survey.Serializer.addProperty(this.name, {
            name: "tramoMinutos",
            displayName: "Duración en minutos del tramo",
            type: "number",
            minValue: 5, // Minimo 5 min
            isRequired: true,
            category: "general",
            visibleIndex: 2,
            dependsOn: ["tipoCita"],
            visibleIf: function (obj) {
                return (
                    obj.tipoCita == "fechaHora"
                );

            },
            isSerializable: true,
        });
        Survey.Serializer.addProperty(this.name, {
            name: "fechaDesde",
            displayName: "Fecha inicio citas",
            type: "string",
            isRequired: true,
            category: "general",
            visibleIndex: 3,
            default: "dd/mm/aaaa",
            isSerializable: true,
            onSetValue: function (question, value) {
                // Comprueba fecha y convierte a Date
                let dFecha = strToDate(value)
                let now = new Date();
                let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
                // Comprueba si la fecha de la cita es anterior al día actual
                if(!dFecha || (dFecha.getTime() < today.getTime())){
                    return false;
                }
                // Comprueba coherencia con la fecha de fin y si no cuadran borra la de fin
                let fechaHasta = question.getPropertyValue("fechaHasta");
                let dFin = strToDate(fechaHasta);
                if(dFin !== false && dFin.getTime() < dFecha.getTime()){
                    question.setPropertyValue("fechaHasta",  null);
                }

                // Pinta la fecha formateada
                let dd = String( dFecha.getDate() ).padStart(2, '0');
                let mm = String( dFecha.getMonth() +1 ).padStart(2, '0'); // mes +1 porque es un array de 0 a 11
                let aaaa = dFecha.getFullYear();
                let fecha = dd + '/' + mm + '/' + aaaa;
                question.setPropertyValue("fechaDesde",  fecha);
            }
        });
        Survey.Serializer.addProperty(this.name, {
            name: "fechaHasta",
            displayName: "Fecha fin citas",
            type: "string",
            isRequired: true,
            category: "general",
            visibleIndex: 4,
            default: "dd/mm/aaaa",
            isSerializable: true,
            onSetValue: function (question, value) {
                // Comprueba fecha y convierte a Date
                let dFecha = strToDate(value)
                let now = new Date();
                let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
                // Comprueba si la fecha de la cita es anterior al día actual
                if(!dFecha || (dFecha.getTime() < today.getTime())){
                    return false;
                }
                // Comprueba coherencia con la fecha de inicio si esta definida
                let fechaDesde = question.getPropertyValue("fechaDesde");
                let dInicio = strToDate(fechaDesde);
                if(dInicio !== false && dInicio.getTime() > dFecha.getTime()){
                    return false;
                }

                // Pinta la fecha formateada
                let dd = String( dFecha.getDate() ).padStart(2, '0');
                let mm = String( dFecha.getMonth() +1 ).padStart(2, '0'); // mes +1 porque es un array de 0 a 11
                let aaaa = dFecha.getFullYear();
                let fecha = dd + '/' + mm + '/' + aaaa;
                question.setPropertyValue("fechaHasta",  fecha);
            }
        });
        Survey.Serializer.addProperty(this.name, {
            name: "tramoCantidad",
            displayName: "Número de citas por tramo",
            type: "number",
            minValue: 1,
            // default: 0,
            // value: 0, // Si solo está el default no lo añade al JSON
            isRequired: true,
            category: "general",
            visibleIndex: 5,
            isSerializable: true,
        });
        Survey.Serializer.addProperty(this.name, {
            name: "dias",
            displayName: "Días disponibles",
            type: "multiplevalues",
            category: "general",
            visibleIndex: 6,
            isRequired: true,
            choices: [
                { value: "lunes", text: "1.Lunes" },
                { value: "martes", text: "2.Martes" },
                { value: "miercoles", text: "3.Miercoles" },
                { value: "jueves", text: "4.Jueves" },
                { value: "viernes", text: "5.Viernes" },
                { value: "sabado", text: "6.Sábado" },
                { value: "domingo", text: "7.Domingo" },
            ],
        });
        Survey.Serializer.addProperty(this.name, {
            name: "horarioInicio",
            displayName: "Horario inicio",
            type: "dropdown",
            category: "general",
            visibleIndex: 7,
            isRequired: true,
            default: "hh:mm",
            dependsOn: ["tipoCita"],
            visibleIf: function (obj) {
                return (
                    obj.tipoCita == "fechaHora"
                );

            },
            choices: function () {
                const choices = [];
                let margen = 15; // 15 min
                // Calculas las posibles opciones de horario en función del margen
                for (let horas = 0; horas < 24; horas++) {
                    for (let min = 0; min < 60; min+=margen) {
                        let sHoras = horas.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                        let sMin = min.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                        choices.push(sHoras+":"+sMin);
                    }
                    
                }
                return choices;
            },
        });
        Survey.Serializer.addProperty(this.name, {
            name: "horarioFin",
            displayName: "Horario fin",
            type: "dropdown",
            category: "general",
            visibleIndex: 8,
            isRequired: true,
            default: "hh:mm",
            dependsOn: ["tipoCita", "horarioInicio"],
            visibleIf: function (obj) {
                return (
                    obj.tipoCita == "fechaHora"
                );

            },
            choices: function (obj) {
                const choices = [];
                // eslint-disable-next-line no-extra-boolean-cast
                let inicio = !!obj["horarioInicio"] ? obj["horarioInicio"].split(":") : "00:00".split(":");
                let horas = parseInt(inicio[0])
                let margen = 15; // 15 min
                // Calculas las posibles opciones de horario en función del margen
                for ( horas += 1; horas < 24; horas++) {
                    for (let min = 0; min < 60; min+=margen) {
                        let sHoras = horas.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                        let sMin = min.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                        choices.push(sHoras+":"+sMin);
                    }
                    
                }
                return choices;
            },
        });
    }
};

/**
 * Comprueba y convierte una fecha de string a Date
 * @param {string} strDate 
 * @returns Fecha de tipo date y false en caso de error
 */
function strToDate(strDate) {
    // Comprueba la estructura de la fecha permitiendo separadores '/' y '-' pero también lo permite mezclados y se controla después
    let pattern = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-](\d{4}$|\d{2})/;
    if (pattern.test(strDate) === false) {
        return false;
    }
    // Separa día, mes y año. Además con esto se pomprueba que los separadores no se mezclen
    let fechaArr = strDate.split("/").length === 3 ? strDate.split("/") : false;
    if (!fechaArr && strDate.split("-").length === 3) {
        fechaArr = strDate.split("-");
    }
    if (fechaArr === false) {
        return false;
    }
    // Convierte año de 2 cifras a 4
    if(fechaArr[2].length === 2){
        fechaArr[2] = "20"+fechaArr[2];
    }
    // Genera un objeto tipo Date
    let dFecha = new Date(fechaArr[2], parseInt(fechaArr[1], 10) - 1, fechaArr[0]) // Mes -1 porque es un array de 0 a 11
    return dFecha;
}


// eslint-disable-next-line no-unused-vars
async function checkDate(date, id_componente){
    var fecha = date.toLocaleDateString("es-ES");
    var body = {
        id_componente: id_componente,
        fecha: fecha,
        tokencrypt: token_encuestas
    }
    // var disponible = true;
    try {
        let res = await axios({
            url: url_api + "citas/reservas",
            method: 'post',
            timeout: 8000,
            headers: {
                'Content-Type': 'application/json',
            },
            data: body
        })
        if(res.status == 200){
            // test for status you want, etc
            console.log(res.data)
            return !res.data.disponible;
        }    
        // Don't forget to return something   
        // return res.data
    } catch (error) {
        console.log(error);
    }
    // await axios.post(url_api + "citas/reservas", body)
    // .then(response => {
    //     console.log(response.data.disponible);
    //     return response.data.disponible;
    // }).catch(error => {
    //     console.log(error);
    // }) 
    // return disponible;
}

async function getReservas(id_componente){
    var body = {
        id_componente: id_componente,
        tokencrypt: token_encuestas
    }
    try {
        let res = await axios({
            url: url_api + "citas/allreservas",
            method: 'post',
            timeout: 8000,
            headers: {
                'Content-Type': 'application/json',
            },
            data: body
        })
        if(res.status == 200){
            reservas = res.data;
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
}